import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Icon from '~/components/Icon'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
  convertUtcDateToLocalTz,
} from '~/utils'

import { EXPORT_TYPE, IMPORT_TYPE } from '../../constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '../../redux/apis/import-export'
import useAttendanceNotExcludingHoliday from '../../redux/hooks/useAttendanceNotExcludingHoliday'
import { ROUTE } from '../../routes/config'
import { STATUS_SYNC_OPTIONS } from './const'
import FilterForm from './filter-form'
import PopupSync from './popup-sync'

const AttendanceNotExcludingHoliday = () => {
  const [isSync, setIsSync] = useState(false)
  const [syncQuery, setSyncQuery] = useState(null)
  const { canAccess } = useApp()

  const history = useHistory()
  const { t } = useTranslation(['buseye'])
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const DEFAULT_FILTERS = {}
  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listAttendanceNotExcludingHoliday: { isLoading, data, total },
      syncAttendanceNotExcludingHoliday: { isLoading: isLoadingSync },
    },
    actions: {
      actGetListAttendanceNotExcludingHoliday,
      actSyncAttendanceNotExcludingHoliday,
    },
  } = useAttendanceNotExcludingHoliday()
  const breadcrumbs = [
    {
      route: ROUTE.ATTENDANCE_NOT_EXCLUDING_HOLIDAY.LIST.PATH,
      title: ROUTE.ATTENDANCE_NOT_EXCLUDING_HOLIDAY.LIST.TITLE,
    },
  ]
  const columns = useMemo(() => [
    {
      field: 'stt',
      headerName: t('attendanceNotExcludingHoliday.stt'),
      width: 80,
      renderCell: (_params, index) => {
        return index + 1 + (page - 1) * pageSize
      },
    },
    {
      field: 'codeEmp',
      headerName: t('attendanceNotExcludingHoliday.empCode'),
      width: 180,
    },
    {
      field: 'nameEmp',
      headerName: t('attendanceNotExcludingHoliday.empName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'nameOrgStructure',
      headerName: t('attendanceNotExcludingHoliday.dept'),
      width: 80,
    },
    {
      field: 'nameRoute',
      headerName: t('attendanceNotExcludingHoliday.routeName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'nameShift',
      headerName: t('attendanceNotExcludingHoliday.shiftName'),
      width: 100,
      sortable: true,
    },
    {
      field: 'timeStart',
      headerName: t('attendanceNotExcludingHoliday.timeStart'),
      width: 60,
      sortable: true,
      renderCell: (params) => {
        const { timeStart } = params?.row
        return convertUtcDateToLocalTz(timeStart, 'HH:mm')
      },
    },
    {
      field: 'timeEnd',
      headerName: t('attendanceNotExcludingHoliday.timeEnd'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { timeEnd } = params?.row
        return convertUtcDateToLocalTz(timeEnd, 'HH:mm')
      },
    },
    {
      field: 'dateStart',
      headerName: t('attendanceNotExcludingHoliday.dayStart'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { dateStart } = params?.row
        return convertUtcDateTimeToLocalTz(dateStart, 'yyyy-MM-dd')
      },
    },
    {
      field: 'dateEnd',
      headerName: t('attendanceNotExcludingHoliday.dayEnd'),
      width: 60,
      sortable: true,
      renderCell: (params) => {
        const { dateEnd } = params?.row
        return convertUtcDateTimeToLocalTz(dateEnd, 'yyyy-MM-dd')
      },
    },
    {
      field: 'dateUpdate',
      headerName: t('attendanceNotExcludingHoliday.dayUpdate'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { dateUpdate } = params?.row
        return convertUtcDateTimeToLocalTz(dateUpdate, 'yyyy-MM-dd')
      },
    },
    {
      field: 'sync',
      headerName: t('attendanceNotExcludingHoliday.sync'),
      width: 60,
      sortable: true,
      renderCell: (params) => {
        const { isSyncSuccess } = params.row
        return (
          <Status
            options={STATUS_SYNC_OPTIONS}
            value={isSyncSuccess}
            variant="text"
          />
        )
      },
    },
    {
      field: 'sourceType',
      headerName: t('attendanceNotExcludingHoliday.source'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { sourceType } = params?.row
        return sourceType === 1 ? 'Import manual' : 'HRMS'
      },
    },
  ])

  const refreshData = () => {
    const { ...dataFilter } = filters
    const filterParam = {
      ...dataFilter,
      orgStructureIds: dataFilter.orgStructureIds?.id,
    }
    if (filterParam.dateWork?.some((el) => el === null))
      delete filterParam.dateWork
    const params = {
      page,
      limit: pageSize,
      filter: convertFilterParams(filterParam, columns),
      // type: tab || 0,
      sort: convertSortParams(sort),
    }
    closeConfirmSync()
    closeSyncPopup()
    actGetListAttendanceNotExcludingHoliday(params)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  const openSyncPopup = () => {
    setIsSync(true)
  }

  const closeSyncPopup = () => {
    setIsSync(false)
  }

  const openConfirmSync = (values) => {
    setSyncQuery(values)
  }

  const closeConfirmSync = () => {
    setSyncQuery(null)
  }

  const handleSyncFailed = () => {
    closeConfirmSync()
  }

  const submitSyncData = () => {
    if (!syncQuery) return
    const query = {
      dateStart: syncQuery.dateStart.toISOString(),
      dateEnd: syncQuery.dateEnd.toISOString(),
    }
    actSyncAttendanceNotExcludingHoliday(query, refreshData, handleSyncFailed)
  }

  const getExport = async (params) => {
    try {
      setIsLoadingExport(true)
      const res = await apiExport(params)
      return res
    } finally {
      setIsLoadingExport(false)
    }
  }

  const topBar = (
    <Box display={'flex'} alignItems={'center'}>
      {canAccess(
        FUNCTION_CODE.USER_SYNCHRONIZE_ROSTER_CALENDAR_EXCLUDE_DAYOFF,
      ) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            cursor: 'pointer',
            borderRight: '1px solid #333',
            paddingRight: '10px',
          }}
          onClick={openSyncPopup}
        >
          <Icon
            name="assign"
            fill="#FF9054"
            stroke="#FF9054"
            sx={{ marginRight: '5px' }}
          />
          {t('common.sync')}
        </Box>
      )}
      <ImportExport
        name={t('importExportMenu.export')}
        onImport={
          canAccess(FUNCTION_CODE.USER_IMPORT_ROSTER_CALENDAR_EXCLUDE_DAYOFF)
            ? (body) =>
                apiImport({
                  body,
                  type: IMPORT_TYPE.ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
                })
            : null
        }
        onExport={
          canAccess(FUNCTION_CODE.USER_EXPORT_ROSTER_CALENDAR_EXCLUDE_DAYOFF)
            ? () => {
                const { ...dataFilter } = filters
                const filterParam = {
                  ...dataFilter,
                  orgStructureIds: dataFilter.orgStructureIds?.id,
                }
                if (filterParam.dateWork?.some((el) => el === null))
                  delete filterParam.dateWork

                const params = {
                  filter: convertFilterParams(filterParam, columns),
                  sort: convertSortParams(sort),
                  type: EXPORT_TYPE.ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
                }
                return getExport(params)
              }
            : null
        }
        onDownloadTemplate={() =>
          apiGetTemplate(EXPORT_TYPE.ATTENDANCE_NOT_EXCLUDING_HOLIDAY)
        }
        onRefresh={refreshData}
      />
    </Box>
  )
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.attendanceNotExcludingHoliday')}
      loading={isLoading || isLoadingSync || isLoadingExport}
      onBack={() => history.goBack()}
    >
      <FilterArea
        tab={tab}
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: <FilterForm />,
          defaultValues: DEFAULT_FILTERS,
          customWidth: {},
        }}
      />
      <div>
        <DataTable
          rows={data}
          pageSize={pageSize}
          page={page}
          columns={columns}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortChange={setSort}
          total={total}
          sort={sort}
          beforeTopbar={topBar}
        />
      </div>
      <PopupSync
        t={t}
        open={isSync}
        onSubmit={openConfirmSync}
        onClose={closeSyncPopup}
        tabName={t('menu.attendanceNotExcludingHoliday')}
      />
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!syncQuery}
        onConfirm={submitSyncData}
        onCancel={closeConfirmSync}
      />
    </Page>
  )
}

export default AttendanceNotExcludingHoliday
