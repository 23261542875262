import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiGetListOrgStructureList } from '~/modules/master/redux/apis/org-structure'
import { convertFilterParams } from '~/utils'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field.TextField
              name="codeEmp"
              labelWidth={70}
              placeholder={t('common.inputKeyWord')}
              inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
              endAdornment={
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <Icon name="search" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Field.DateRangePicker
              name="dateWork"
              labelWidth={70}
              placeholder={t('attendanceNotExcludingHoliday.filter.workingDay')}
            />
          </Grid>
          <Grid item xs={3}>
            <Field.Autocomplete
              name="orgStructureIds"
              placeholder={t('profileCustom.orgStructure')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListOrgStructureList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                  filter: convertFilterParams({
                    isActive: STATUS_VALUE.active,
                  }),
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FilterForm
