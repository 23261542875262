import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import TaskBar from '~/components/TaskBar'
import { EXPORT_REPORT_TYPE } from '~/modules/master/constants'
import { apiExportReport } from '~/modules/master/redux/apis/import-export'
import useReportBr11 from '~/modules/master/redux/hooks/useReportBr11'
import { ROUTE } from '~/modules/master/routes/config'
import { useClasses } from '~/themes'
import { convertFilterParams, convertSortParams } from '~/utils'

import { enumerateDaysBetweenDates } from '../constants'
import FilterForm from './filter-form'
import { validationSchema } from './filter-form/schema'
import style from './style'
import TotaList from './total-list'

const breadcrumbs = [
  {
    title: ROUTE.REPORT.TITLE,
  },
  {
    route: ROUTE.REPORT.REPORT_BR11.LIST.PATH,
    title: ROUTE.REPORT.REPORT_BR11.LIST.TITLE,
  },
]

// const { start, end } = dateFilterDefault()

// const DEFAULT_FILTERS = {
//   rangeDate: [start, end],
// }

const DEFAULT_FILTERS = {
  rangeDate: [
    moment().subtract(1, 'months').set('date', 21),
    moment().set('date', 20),
  ],
}

const ReportBr11 = () => {
  const { t } = useTranslation(['buseye'])
  const classes = useClasses(style)

  const history = useHistory()

  const {
    page,
    pageSize,
    sort,
    filters,
    keyword,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })
  const [confirmSync, setConfirmSync] = useState(false)

  const {
    data: {
      listReportBr11: { data, total, isLoading },
      listReportBr11Summary: { data: dataSummary, total: totalSummary },
      syncReportBr11: { isLoading: syncLoading },
    },
    actions: { actGetReportBr11, actGetReportBr11Summary, actSyncReportBr11 },
  } = useReportBr11()

  const columns = useMemo(() => {
    const { rangeDate } = filters
    const listMonth = enumerateDaysBetweenDates(rangeDate[0], rangeDate[1])

    const newColumns = Object.keys(listMonth).reduce(
      (acc, monthKey) => {
        const month = listMonth[monthKey]
        const column = {
          field: monthKey,
          headerName: month.title,
          align: 'center',
          visible: 'always',
          noCache: true,
        }
        if (month.days.length > 0) {
          column.columns = month.days.map((day) => ({
            field: day.key,
            headerName: day.title,
            width: 60,
            align: 'center',
            visible: 'always',
            noCache: true,
            renderCell: (params) => {
              const { row } = params
              return (
                <Box
                  sx={{
                    background: day.isWeekend ? '#E1E1E1' : '',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {row[day.key]}
                </Box>
              )
            },
          }))
        }
        column.width = column.columns.length * 60
        acc.push(column)
        return acc
      },
      [
        {
          field: 'STT',
          headerName: 'STT',
          width: 80,
          align: 'center',
          renderCell: (_, idx) => {
            return idx + 1
          },
        },
        {
          field: 'code',
          headerName: t('reportBr11.codeEmp'),
          width: 150,
          align: 'center',
        },
        {
          field: 'nameOrgStructure',
          headerName: t('reportBr11.bu'),
          width: 150,
          align: 'center',
        },
        {
          field: 'name',
          headerName: t('reportBr11.nameEmp'),
          width: 150,
          align: 'center',
        },
        {
          field: 'routeName',
          headerName: t('reportBr11.routeName'),
          width: 150,
          align: 'center',
        },
        {
          field: 'pickupPointName',
          headerName: t('reportBr11.pickupPoint'),
          width: 150,
          align: 'center',
        },
      ],
    )
    return newColumns
  }, [filters, data])

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, keyword, tab])

  const refreshData = () => {
    const { orgStructures, ...paramsFilters } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          ...paramsFilters,
          orgStructureCodes: orgStructures?.map((item) => item.code),
          codeRoutes: filters.codeRoutes?.map((item) => item.code),
          codeShift: filters.codeShift?.code,
          pickupPointCodes: filters.pickupPointCodes?.map((item) => item.code),
        },
        [
          ...columns,
          { field: 'rangeDate', filterFormat: 'date' },
          { field: 'orgStructureIds', filterFormat: 'multiple' },
        ],
      ),
      sort: convertSortParams(sort),
    }
    actGetReportBr11(params)
    actGetReportBr11Summary(params)
    // actions.searchProducingSteps(params)
  }

  const openConfirm = () => {
    setConfirmSync(true)
  }

  const closeConfirmSync = () => {
    setConfirmSync(false)
  }

  const handleSyncData = () => {
    actSyncReportBr11(
      {},
      () => {
        refreshData()
        closeConfirmSync()
      },
      closeConfirmSync,
    )
  }

  const syncButton = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
        marginRight: '20px',
      }}
      onClick={openConfirm}
    >
      <Icon
        name="assign"
        fill="#FF9054"
        stroke="#FF9054"
        sx={{ marginRight: '5px' }}
      />
      {t('common.sync')}
    </Box>
  )

  const exportButton = (
    <Guard code={FUNCTION_CODE.USER_EXPORT_REPORT_ROSTER_CALENDAR}>
      <ImportExport
        name={t('reportBr11.fileNameExport')}
        onExport={() => {
          const { orgStructures, ...paramsFilters } = filters
          const params = {
            filter: convertFilterParams(
              {
                ...paramsFilters,
                orgStructureCodes: orgStructures?.map((item) => item.code),
                codeRoutes: filters.codeRoutes?.map((item) => item.code),
                codeShift: filters.codeShift?.code,
                pickupPointCodes: filters.pickupPointCodes?.map(
                  (item) => item.code,
                ),
              },
              [
                ...columns,
                { field: 'rangeDate', filterFormat: 'date' },
                { field: 'orgStructureIds', filterFormat: 'multiple' },
              ],
            ),
            type: EXPORT_REPORT_TYPE.ROSTER_CALENDAR,
          }

          return apiExportReport(params)
        }}
      />
    </Guard>
  )

  const beforeTopbar = [syncButton, exportButton]

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.reportBr11')}
      loading={isLoading || syncLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <TaskBar left={[]} right={beforeTopbar} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_REPORT_ROSTER_CALENDAR}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
            validationSchema: validationSchema(t),
            customWidth: {},
          }}
        />
      </Guard>
      <div className={classes.tableContainer}>
        <DataTable
          rows={data || []}
          pageSize={pageSize}
          page={page}
          columns={columns}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortChange={setSort}
          total={total}
          // title={t('master.title')}
          sort={sort}
          hideSetting
          // tabs={tabElement}
          uniqKey={'1'}
        />
      </div>
      <Box sx={{ mt: 1 }}>
        <TotaList data={dataSummary} total={totalSummary} />
      </Box>
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!confirmSync}
        onConfirm={handleSyncData}
        onCancel={closeConfirmSync}
      />
    </Page>
  )
}

export default ReportBr11
