import React, { useEffect, useMemo, useState } from 'react'

import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import TaskBar from '~/components/TaskBar'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
} from '~/utils'

import { EXPORT_TYPE } from '../../constants'
import { apiExport, apiGetTemplate } from '../../redux/apis/import-export'
import { apiGetListPickupPointList } from '../../redux/apis/pickup-point'
import useReportCheckInEmployee from '../../redux/hooks/useReportCheckInEmployee'
import { ROUTE } from '../../routes/config'
import { COORDINATES_STATUS } from './constants'
import FilterForm from './filter-form'

const DEFAULT_FILTERS = {
  checkinDate: [
    moment().subtract(1, 'months').set('date', 21),
    moment().set('date', 20),
  ],
}
const ReportCheckInEmployee = () => {
  const history = useHistory()
  const [isWrongCheckIn, setIsWrongCheckIn] = useState({
    route: false,
    pickupPoint: false,
  })
  const [optionPickupPoint, setOptionPickupPoint] = useState([])
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const { canAccess } = useApp()
  const [disabled, setDisabled] = useState(false)
  const { t } = useTranslation(['buseye'])
  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listReportCheckInEmployee: { isLoading, data, total },
    },
    actions: { actGetListCheckInEmployee },
  } = useReportCheckInEmployee()
  const breadcrumbs = [
    {
      title: ROUTE.REPORT.TITLE,
    },
    {
      route: ROUTE.REPORT.REPORT_CHECK_IN_EMPLOYEE.LIST.PATH,
      title: ROUTE.REPORT.REPORT_CHECK_IN_EMPLOYEE.LIST.TITLE,
    },
  ]
  const statusCheckin = (status) => {
    if (status === COORDINATES_STATUS.PP_COORDINATES_INVALID) {
      return t('reportCheckInEmployee.ppCoordinatesInvalid')
    }
    if (status === COORDINATES_STATUS.CHECKIN_COORDINATES_INVALID) {
      return t('reportCheckInEmployee.checkInCoordinatesInvalid')
    }
    if (status === COORDINATES_STATUS.DISTANCE_INVALID) {
      return t('reportCheckInEmployee.distanceInvalid')
    }
    if (status === COORDINATES_STATUS.DISTANCE_VALID) {
      return t('reportCheckInEmployee.distanceValid')
    }
    return ''
  }

  const columns = useMemo(() => [
    {
      field: 'emp_code',
      headerName: t('reportCheckInEmployee.employeeCode'),
      width: 150,
      visible: 'always',
      sortable: true,
    },
    {
      field: 'name_emp',
      headerName: t('reportCheckInEmployee.employeeName'),
      width: 80,
    },
    {
      field: 'sectionName',
      headerName: t('reportCheckInEmployee.sectionName'),
      width: 80,
    },
    {
      field: 'unitName',
      headerName: t('reportCheckInEmployee.unitName'),
      width: 80,
    },
    {
      field: 'routeCode',
      headerName: t('reportCheckInEmployee.routeCode'),
      width: 150,
      sortable: true,
    },
    {
      field: 'routeName',
      headerName: t('reportCheckInEmployee.routeName'),
      width: 80,
    },
    {
      field: 'pickupPointCode',
      headerName: t('reportCheckInEmployee.pointCode'),
      width: 60,
      sortable: true,
    },
    {
      field: 'pickupPointName',
      headerName: t('reportCheckInEmployee.pointName'),
      width: 100,
      sortable: true,
    },
    {
      field: 'checkinDate',
      headerName: t('reportCheckInEmployee.timeCheckIn'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { checkinDate } = params?.row
        return convertUtcDateTimeToLocalTz(checkinDate)
      },
    },
    {
      field: 'routeCodeCheckin',
      headerName: t('reportCheckInEmployee.routeCodeCheckIn'),
      width: 100,
      sortable: true,
    },
    {
      field: 'routeNameCheckin',
      headerName: t('reportCheckInEmployee.routeCheckIn'),
      width: 100,
      sortable: true,
    },
    {
      field: 'pickupPointNameCheckin',
      headerName: t('reportCheckInEmployee.pointCheckIn'),
      width: 100,
      sortable: true,
    },
    {
      field: 'shiftName',
      headerName: t('reportCheckInEmployee.shiftCode'),
      width: 100,
      sortable: true,
    },
    {
      field: 'direction',
      headerName: t('reportCheckInEmployee.direction'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { direction } = params?.row
        return convertNameDirection(direction)
      },
    },
    {
      field: 'isWrongRouteCheckIn',
      headerName: t('reportCheckInEmployee.wrongRouteCheckIn'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { routeCode, routeCodeCheckin } = params?.row
        return (
          <Checkbox checked={!isEqual(routeCode, routeCodeCheckin)} disabled />
        )
      },
    },
    {
      field: 'isWrongPointCheckIn',
      headerName: t('reportCheckInEmployee.wrongPointCheckIn'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { pickupPointCode, pickupPointCodeCheckin } = params?.row
        return (
          <Checkbox
            checked={!isEqual(pickupPointCode, pickupPointCodeCheckin)}
            disabled
          />
        )
      },
    },
    {
      field: 'status',
      headerName: t('reportCheckInEmployee.status'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { status } = params?.row
        return statusCheckin(status)
      },
    },
  ])

  const convertNameDirection = (direction) => {
    if (direction === 'CHIEU_DI')
      return t('reportCheckInEmployee.travelDirection')
    return t('reportCheckInEmployee.returnTravelDirection')
  }

  const refreshData = () => {
    const { ...dataFilter } = filters
    const filterParam = {
      ...dataFilter,
      route: filters.route?.code,
      shiftCode: filters.shiftCode?.code,
      pickupPoint: filters.pickupPoint,
    }
    if (filterParam.checkinDate?.some((el) => el === null))
      delete filterParam.checkinDate
    const params = {
      page,
      limit: pageSize,
      filter: convertFilterParams(filterParam, columns),
      // type: tab || 0,
      sort: convertSortParams(sort),
    }
    actGetListCheckInEmployee(params)
  }

  useEffect(() => {
    actGetListCheckInEmployee()
  }, [])

  const getOptionPickupPoint = async (route) => {
    try {
      const res = await apiGetListPickupPointList({
        keyword: route,
        limit: ASYNC_SEARCH_LIMIT,
      })
      if (res?.data?.items) {
        const option = res.data.items.map((item) => ({
          label: item.name,
          value: item.code,
        }))
        setOptionPickupPoint(option)
      }
    } catch (error) {}
  }
  useEffect(() => {
    getOptionPickupPoint()
  }, [])

  const getExport = async (params) => {
    try {
      setIsLoadingExport(true)
      const res = await apiExport(params)
      return res
    } finally {
      setIsLoadingExport(false)
    }
  }

  let actionHeader = [
    <ImportExport
      name={t('importExportMenu.export')}
      onExport={
        canAccess(FUNCTION_CODE.USER_EXPORT_CHECKIN)
          ? () => {
              const { ...dataFilter } = filters
              const filterParam = {
                ...dataFilter,
                route: filters.routeCodeCheckin?.code,
                shiftCode: filters.shiftCode?.code,
                pickupPoint: filters.pickupPointCodeCheckin?.code,
              }
              if (!tab) delete filterParam.status
              if (filterParam.updatedAt?.some((el) => el === null))
                delete filterParam.updatedAt

              const params = {
                filter: convertFilterParams(filterParam, columns),
                sort: convertSortParams(sort),
                type: EXPORT_TYPE.REPORT_CHECK_IN_EMPLOYEE,
              }
              return getExport(params)
            }
          : null
      }
      onDownloadTemplate={() =>
        apiGetTemplate(EXPORT_TYPE.REPORT_CHECK_IN_EMPLOYEE)
      }
      onRefresh={refreshData}
    />,
  ]

  const CHECK_IN_TYPES = {
    WRONG_ROUTE: 'WRONGROUTE',
    WRONG_PICKUP_POINT: 'WRONGPICKUPPOINT',
  }

  const createFilterParams = (filters, type) => {
    const { ...dataFilter } = filters
    const filterParam = {
      ...dataFilter,
      ...(type && { type }),
    }

    if (!type) {
      delete filterParam.type
    }

    return filterParam
  }

  const createRequestParams = (filterParam, page, pageSize, sort, columns) => {
    if (filterParam.updatedAt?.some((el) => el === null)) {
      delete filterParam.updatedAt
    }
    return {
      page,
      limit: pageSize,
      filter: convertFilterParams(filterParam, columns),
      sort: convertSortParams(sort),
    }
  }

  const handleGetWrongRouteCheckIn = (event) => {
    const isChecked = event.target.checked
    const { pickupPoint } = isWrongCheckIn
    let type = null
    if (isChecked || (isChecked && pickupPoint)) {
      type = CHECK_IN_TYPES.WRONG_ROUTE
    } else if (pickupPoint) {
      type = CHECK_IN_TYPES.WRONG_PICKUP_POINT
    }
    const filterParam = createFilterParams(
      filters,
      filters.registerType === 'ROUTE' ? null : type,
    )
    setFilters(filterParam)
    const params = createRequestParams(
      filterParam,
      page,
      pageSize,
      sort,
      columns,
    )

    actGetListCheckInEmployee(params)
    setIsWrongCheckIn({
      route: isChecked,
      pickupPoint,
    })
  }
  const handleGetWrongPointCheckIn = (event) => {
    const isChecked = event.target.checked
    const { route } = isWrongCheckIn

    let type = null
    if (isChecked && route) {
      type = CHECK_IN_TYPES.WRONG_ROUTE
    } else if (isChecked && !route) {
      type = CHECK_IN_TYPES.WRONG_PICKUP_POINT
    } else if (!isChecked && route) {
      type = CHECK_IN_TYPES.WRONG_ROUTE
    } else if (isChecked) {
      type = CHECK_IN_TYPES.WRONG_PICKUP_POINT
    }

    const filterParam = createFilterParams(filters, type)
    setFilters(filterParam)
    const params = createRequestParams(
      filterParam,
      page,
      pageSize,
      sort,
      columns,
    )

    actGetListCheckInEmployee(params)
    setIsWrongCheckIn({
      pickupPoint: isChecked,
      route,
    })
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    const IsWrongCheckIn = {
      route: filters.type === CHECK_IN_TYPES.WRONG_ROUTE,
      pickupPoint: filters.type === CHECK_IN_TYPES.WRONG_PICKUP_POINT,
    }
    setIsWrongCheckIn(IsWrongCheckIn)
  }, [filters])

  useEffect(() => {
    setDisabled(filters?.registerType === 'ROUTE')
  }, [filters?.registerType])
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.checkInEmployee')}
      loading={isLoading || isLoadingExport}
      onBack={() => history.goBack()}
    >
      <TaskBar left={actionHeader} />
      <FilterArea
        tab={tab}
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: (
            <FilterForm
              optionPickupPoint={optionPickupPoint}
              getOptionPickupPoint={getOptionPickupPoint}
            />
          ),
          defaultValues: DEFAULT_FILTERS,
        }}
      />
      <div>
        <Box flex gap={2}>
          <FormControlLabel
            sx={{ marginRight: 2 }}
            control={
              <Checkbox
                checked={isWrongCheckIn.route}
                disabled={disabled}
                onChange={handleGetWrongRouteCheckIn}
              />
            }
            label={t('reportCheckInEmployee.wrongRouteCheckIn')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isWrongCheckIn.pickupPoint}
                onChange={handleGetWrongPointCheckIn}
              />
            }
            label={t('reportCheckInEmployee.wrongPointCheckIn')}
          />
        </Box>
        <DataTable
          rows={data}
          pageSize={pageSize}
          page={page}
          columns={columns}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortChange={setSort}
          total={total}
          sort={sort}
        />
      </div>
    </Page>
  )
}

export default ReportCheckInEmployee
