export const API_PATH = '/buseyes'

export const STATUS_PROFILE_CUSTOM_VALUE = {
  working: 'Đang làm việc',
  maternityLeave: 'Nghỉ thai sản', // thai sản
  quit: 'Đã nghỉ việc',
}

export const STATUS_PROFILE_CUSTOM_OPTIONS = [
  {
    id: STATUS_PROFILE_CUSTOM_VALUE.working,
    text: 'common.working',
    color: 'confirmed',
  },
  {
    id: STATUS_PROFILE_CUSTOM_VALUE.maternityLeave,
    text: 'common.maternityLeave',
    color: 'inProgress',
  },
  {
    id: STATUS_PROFILE_CUSTOM_VALUE.quit,
    text: 'common.quit',
    color: 'rejected',
  },
]

export const STATUS_SYN_VALUE = {
  error: 0,
  success: 1,
}

export const STATUS_SYN_OPTION = [
  {
    id: STATUS_SYN_VALUE.error,
    text: 'common.error',
    color: 'rejected',
  },
  {
    id: STATUS_SYN_VALUE.success,
    text: 'common.success',
    color: 'active',
  },
]

export const STATUS_VALUE = {
  pending: 0,
  active: 1,
}

export const STATUS_OPTIONS = [
  {
    id: STATUS_VALUE.pending,
    text: 'common.pending',
    color: 'rejected',
  },
  {
    id: STATUS_VALUE.active,
    text: 'common.active',
    color: 'active',
  },
]

export const SHIFT_TYPE = {
  OFFICE_TIME: 'OFFICE_TIME',
  OVERTIME: 'OVERTIME',
  BUSSINESS_TIME: 'BUSSINESS_TIME',
}

export const YES_NO_VALUE = {
  no: 0,
  yes: 1,
}

export const APPROVED_VALUE = {
  approved: 'E_APPROVED',
  reject: 'E_REJECT',
}

export const APPROVED_OPTIONS = [
  {
    id: APPROVED_VALUE.reject,
    text: 'common.noApprove',
    color: 'rejected',
  },
  {
    id: APPROVED_VALUE.approved,
    text: 'common.approve',
    color: 'active',
  },
]

export const VEHICLE_TYPE_VALUE = {
  taxi: 'TAXI',
  grab: 'GRAB',
  route: 'ROUTE',
}

export const PROFILE_CUSTOM_FLAG = {
  WORKING: 0,
  UNREGISTER_ROUT: 1,
}

export const IMPORT_TYPE = {
  VEHICLE_CATEGORY: 0,
  ROUTE: 1,
  PICKUP_POINT: 2,
  PROFILE_CUSTOM: 3,
  FACTORY: 4,
  ROSTER_CALENDAR: 5,
  USER_MANAGEMENT: 6,
  ATTENDANCE_NOT_EXCLUDING_HOLIDAY: 7,
}

export const TYPE_OT_AND_BT = {
  Overtime: 'Ngoài giờ',
  BussinessTravel: 'Công tác',
}

export const TYPE_OT_AND_BT_OPTIONS = [
  {
    id: TYPE_OT_AND_BT.Overtime,
    text: 'reportOt.Overtime',
  },
  {
    id: TYPE_OT_AND_BT.BussinessTravel,
    text: 'reportOt.BussinessTravel',
  },
]

export const EXPORT_TYPE = {
  VEHICLE_CATEGORY: 0,
  ROUTE: 1,
  PICKUP_POINT: 2,
  ROSTER_CALENDAR: 3,
  OVERTIME_PLAN: 4,
  BUSSINESS_TRAVEL: 5,
  PROFILE_CUSTOM: 6,
  FACTORY: 7,
  USER_MANAGEMENT: 8,
  VEHICLE_REGISTER: 9,
  ROUTE_POINT_REGISTER: 10,
  REPORT_CHECK_IN_EMPLOYEE: 11,
  REPORT_BUS_NOT_CHECK_IN: 12,
  REPORT_BUSINESS_TRAVEL_NOT_CHECK_IN: 13,
  ATTENDANCE_NOT_EXCLUDING_HOLIDAY: 14,
}

export const EXPORT_REPORT_TYPE = {
  ROSTER_CALENDAR: 0,
  OVERTIME_PLAN_AND_BUSSINESS_TRAVEL: 1,
  OVERALL: 2,
  FEE_DISTRIBUTION: 3,
}

export const SOURCE_TYPE = {
  0: 'HRM',
  1: 'Excel',
}

export const SEND_MAIL_TYPE = {
  ATTENDANCE: 0,
  REPORT: 1,
}

export const UNIT_LEVEL_ENUM = {
  UNIT: 5,
  SECTION: 4,
  DEPARTMENT: 3,
  FACTORY: 2,
  DIVISION: 1,
}

export const LGA_VEHICLE_VALUE = {
  NO: 0,
  YES: 1,
}

export const REGEX_MAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ // eslint-disable-line
