import React, { useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import TaskBar from '~/components/TaskBar'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
} from '~/utils'

import { EXPORT_TYPE } from '../../constants'
import { apiExport, apiGetTemplate } from '../../redux/apis/import-export'
import { apiGetListPickupPointList } from '../../redux/apis/pickup-point'
import useReportBusNotCheckIn from '../../redux/hooks/useReportBusNotCheckIn'
import { ROUTE } from '../../routes/config'
import FilterForm from './filter-form'

const DEFAULT_FILTERS = {
  dateStart: [
    moment().subtract(1, 'months').set('date', 21),
    moment().set('date', 20),
  ],
}
const ReportBusNotCheckIn = () => {
  const history = useHistory()
  const [optionPickupPoint, setOptionPickupPoint] = useState([])
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const { canAccess } = useApp()

  const { t } = useTranslation(['buseye'])
  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listReportBusNotCheckIn: { isLoading, data, total },
    },
    actions: { actGetListBusNotCheckIn },
  } = useReportBusNotCheckIn()
  const breadcrumbs = [
    {
      title: ROUTE.REPORT.TITLE,
    },
    {
      route: ROUTE.REPORT.REPORT_BUSINESS_TRAVEL_NOT_CHECK_IN.LIST.PATH,
      title: ROUTE.REPORT.REPORT_BUSINESS_TRAVEL_NOT_CHECK_IN.LIST.TITLE,
    },
  ]
  const columns = useMemo(() => [
    {
      field: 'code_emp',
      headerName: t('reportBusNotCheckIn.employeeCode'),
      width: 150,
      visible: 'always',
      sortable: true,
    },
    {
      field: 'name_emp',
      headerName: t('reportBusNotCheckIn.employeeName'),
      width: 80,
    },
    {
      field: 'sectionName',
      headerName: t('reportBusNotCheckIn.sectionName'),
      width: 80,
    },
    {
      field: 'unitName',
      headerName: t('reportBusNotCheckIn.unitName'),
      width: 80,
    },
    {
      field: 'codeRoute',
      headerName: t('reportBusNotCheckIn.routeCode'),
      width: 150,
      sortable: true,
    },
    {
      field: 'nameRoute',
      headerName: t('reportBusNotCheckIn.routeName'),
      width: 100,
      sortable: true,
    },
    {
      field: 'pickupPointCode',
      headerName: t('reportBusNotCheckIn.pointCode'),
      width: 60,
      sortable: true,
    },
    {
      field: 'pickupPointName',
      headerName: t('reportBusNotCheckIn.pointName'),
      width: 100,
      sortable: true,
    },
    {
      field: 'name_shift',
      headerName: t('reportBusNotCheckIn.shift'),
      width: 80,
    },
    {
      field: 'date_start',
      headerName: t('reportBusNotCheckIn.dateGoing'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { date_start } = params?.row
        return convertUtcDateTimeToLocalTz(date_start, 'yyyy-MM-dd')
      },
    },
    {
      field: 'checkinId',
      headerName: t('reportBusNotCheckIn.type'),
      width: 80,
      renderCell: (params) => {
        const { checkinId } = params?.row
        return checkinId ? t('common.yes') : t('common.no')
      },
    },
  ])

  const refreshData = () => {
    const { codeEmp, ...dataFilter } = filters
    const filterParam = {
      ...dataFilter,
      shiftCode: filters.shiftCode?.code,
      route: filters.route?.code,
      pickupPoint: filters.pickupPoint,
    }
    if (filterParam.dateStart?.some((el) => el === null))
      delete filterParam.dateStart
    const params = {
      page,
      limit: pageSize,
      keyword: codeEmp,
      filter: convertFilterParams(filterParam, columns),
      // type: tab || 0,
      sort: convertSortParams(sort),
    }
    actGetListBusNotCheckIn(params)
  }

  const getExport = async (params) => {
    try {
      setIsLoadingExport(true)
      const res = await apiExport(params)
      return res
    } finally {
      setIsLoadingExport(false)
    }
  }

  let actionHeader = [
    <ImportExport
      name={t('importExportMenu.export')}
      onExport={
        canAccess(FUNCTION_CODE.USER_EXPORT_ROSTER_CALENDAR_NOT_CHECKIN)
          ? () => {
              const { ...dataFilter } = filters
              const filterParam = {
                ...dataFilter,
                shiftCode: filters.shiftCode?.code,
                route: filters.route?.code,
                pickupPoint: filters.pickupPoint?.code,
              }
              if (!tab) delete filterParam.status
              if (filterParam.updatedAt?.some((el) => el === null))
                delete filterParam.updatedAt

              const params = {
                filter: convertFilterParams(filterParam, columns),
                sort: convertSortParams(sort),
                type: EXPORT_TYPE.REPORT_BUS_NOT_CHECK_IN,
              }

              return getExport(params)
            }
          : null
      }
      onDownloadTemplate={() =>
        apiGetTemplate(EXPORT_TYPE.REPORT_BUS_NOT_CHECK_IN)
      }
      onRefresh={refreshData}
    />,
  ]

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])
  const getOptionPickupPoint = async (route) => {
    try {
      const res = await apiGetListPickupPointList({
        keyword: route,
        limit: ASYNC_SEARCH_LIMIT,
      })
      if (res?.data?.items) {
        const option = res.data.items.map((item) => ({
          label: item.name,
          value: item.code,
        }))
        setOptionPickupPoint(option)
      }
    } catch (error) {}
  }
  useEffect(() => {
    getOptionPickupPoint()
  }, [])

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.busNotCheckIn')}
      loading={isLoading || isLoadingExport}
      onBack={() => history.goBack()}
    >
      <TaskBar left={actionHeader} />
      <FilterArea
        tab={tab}
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: (
            <FilterForm
              getOptionPickupPoint={getOptionPickupPoint}
              optionPickupPoint={optionPickupPoint}
            />
          ),
          defaultValues: DEFAULT_FILTERS,
        }}
      />
      <div>
        <DataTable
          rows={data}
          pageSize={pageSize}
          page={page}
          columns={columns}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortChange={setSort}
          total={total}
          sort={sort}
        />
      </div>
    </Page>
  )
}

export default ReportBusNotCheckIn
